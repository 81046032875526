/**
 * Overwrites
 */
@brand-primary:			#053525;
@brand-secondary:		#525761;
@brand-tertiary:		rgb(0,150,136);//#37AA32;


@link-color: 			@brand-secondary;
@link-color-hover: 		@brand-primary;

@background-body: #FAF9F5;
@background-primary: #FAF9F5;
@background-secondary: #fff;

@navigation-background: rgb(106,134,124); // #8E9090;
@navigation-colour: #F7F5EF;//@brand-secondary;
@navigation-border: lighten(@navigation-background, 5%);// #F7F5EF;
@navigation-width: 15em;
@navigation-active-background: @brand-secondary;
@navigation-active-colour: @background-primary;

@headers-colour: @brand-secondary;
@headers-colour-secondary: @brand-secondary;

@btn-primary-colour: #fff;
@btn-primary-bg: #009688;
@btn-primary-border: #009688;


@btn-secondary-color: #fff;
@btn-secondary-bg: @brand-secondary;
@btn-secondary-border: @brand-secondary;

@input-border-radius: 2px;
//@input-height-base: @font-size-base*3.8;
@padding-base-vertical: 10px;

/** Web Fonts */
@font-family-normal: 'Univers', 'Helvetica', 'Arial', sans-serif;
@font-family-heavy: 'Glypha','Helvetica', 'Arial', sans-serif;

/**
 * GWR Colours
 */
@gwr-green: @brand-primary;
@gwr-slate: #525761;
@gwr-electric-green: #37AA32;

@gwr-teal: #009688;
@gwr-yellow: #FADC64;
@gwr-orange: #F69143;
@gwr-red: #F64F5F;
@gwr-plum: #4A2C4D;
@gwr-grass: #4DC386;
@gwr-sky: #76CCE1;

@gwr-grey-a: #8E9090;
@gwr-grey-b: #E0DED8;
@gwr-grey-c: #F7F5EF;

@gwr-black: #000;
@gwr-white: #fff;

@gwr-pidd-good: #469900;
@gwr-pidd-minor: #ff7700;
@gwr-pidd-major: #e53935;


@brand-alert:		@gwr-pidd-major;
@brand-warning:		@gwr-pidd-minor;
@brand-success:		@gwr-pidd-good;

@horizontal-padding: 24px;

@import 'mixins';
@import 'normalize';

body {
    background: @background-body;
    color: @brand-secondary;
    font-family: Univers, Helvetica, Arial, sans-serif;
    overflow-x: hidden;
}

h1,h2 {
    font-family: Glypha, Helvetica, Arial, sans-serif;
}
h2 {
    font-size:1.2em;
    line-height:1.8em;
}
body, p, label {
    line-height: 1.8em;
}

a {
    color: @brand-secondary;
    text-decoration:none;
    font-weight:bold;

    &:hover {
        text-decoration:underline;
        color: @brand-primary;
    }
}

p, .error, .confirmation, li {
    line-height: 1.8em;
}
.container {
    margin:auto;
    max-width: 780px;
    padding: 0 @horizontal-padding;
}

header.global {
    background: @background-secondary;
    .clearfix();
    padding: .5em 0;

    .brand {
        float: left;

        h1 {
            font-size: 1em;
            margin: 0 0 0 .3em;


        }

        img {
            height: 40px;
            float: left;
            margin: 0 1em 0 0;
        }

        span {
            display:inline-block;
            padding: 1em 0 0 0;
        }
    }

    nav {
        float:right;

        ul {
            margin: 0;
            padding: 0;

            li {
                float: left;
                list-style-type:none;
                margin-left: 1em;

                a {
                    display:block;
                    padding: 1em 0 .5em 1em;
                }
            }
        }
    }
}
.hero {
    height: 360px;
    background: url(/img/hero.jpg) no-repeat top center;
    background-size:cover;

    .container {
        //padding-top: 120px;
        position:relative;
        height: 360px;
    }

    h1 {
        font-size: 48px;
        font-weight:normal;
        margin: 0;
        color: @background-secondary;
        position: absolute;
        bottom: 2em;
        left: @horizontal-padding;
        right: 0;

        span {
            display:block;
            height: 2px;
            width: 100%;
            position:relative;
            background: @background-secondary;
            margin-top: 10px;

            &:after {
                height:100%;
                width: 100%;
                left: 100%;
                display:block;
                content: "";

                position:absolute;
                background: @background-secondary;
            }
        }
    }
}

@container-header-height:18px;
main.container {
    //margin: -(@horizontal-padding+@container-header-height+@container-header-height) auto @horizontal-padding;
    margin: -66px auto 24px;
    background: @background-secondary;
    padding: 0;

    .clearfix();

    header {
        background: @brand-primary;
        color: @background-primary;
        padding: @horizontal-padding;
        //height: 3.5em;

        h1 {
            font-size: @container-header-height;
            margin: 0;
            line-height:1em;
        }
    }

    .content {
        padding: 1em;
    }


    form {
        fieldset {
            margin: 0;
            border: 0px none;
            padding: @horizontal-padding;
            //padding-left: @horizontal-padding;
            //padding-right:@horizontal-padding;

            legend {
                font-weight: bold;
                display:block;
            }

            div {
                position:relative;
                padding: 1em 0;

                label {
                    display:block;
                    //padding: 0 1em;
                    font-weight: bold;
                    margin-bottom: 1em;
                    padding-left:.5em;
                }

                input,select {
                    display:block;
                    line-height: 2.5em;
                    height: 2.7em;
                    border: 1px solid    @gwr-grey-b;
                    background: @background-secondary;

                    border-bottom: 3px solid @gwr-grey-b;
                    border-radius: .3em;
                    width: 100%;
                    box-sizing:border-box;
                    padding: 0 .5em;
                    outline: 0;

                    &:active, &:focus {
                        border-bottom-color: @brand-secondary;
                    }
                }

                select {
                    height: 2.75em;
                }

                .button {
                    .clearfix();
                }

                button {
                    background: @gwr-teal;
                    color: @background-secondary;
                    padding: .8em 2em;
                    display:inline-block;
                    float:right;
                    border: 1px solid @gwr-teal;
                    border-radius: .3em;
                    font-weight:bold;
                    text-align:center;
                    margin-bottom: .3em;

                    &:active {
                        background: lighten(@gwr-teal, 10%);
                    }

                    &:disabled {
                        background: @gwr-slate;
                    }

                    &.pull-left {
                        float: left;
                    }
                }

                .hint {
                    position: absolute;
                    top:.8em;
                    left: 0;
                    right:0;

                    i {
                        position: absolute;
                        top:0;
                        right:0;

                        font-style:normal;
                        color: @background-body;
                        background: @brand-secondary;
                        display: block;
                        padding: .2em 0 .1em;
                        width: 1.5em;
                        text-align:center;
                        border-radius: .3em;
                    }

                    .tooltip {
                        cursor:help;
                        display:none;
                        opacity: 0;
                        position:absolute;
                        bottom:-2px;
                        right:0;
                        left: 35%;
                        background: @background-secondary;
                        border: 1px solid @brand-secondary;
                        border-radius: .3em;
                        font-size:.8em;
                        padding: 1.2em;

                        transition: all 2s ease-in-out;

                        h2 {
                            font-size:1.2em;
                            margin-top: 0;
                        }
                        h3 {
                            font-size: 1em;
                            margin-bottom: 0;
                        }

                        p {
                            margin: 0;
                        }
                    }

                    &:hover {
                        .tooltip {
                            opacity: 1;
                            display:block;
                            transition: opacity .2s ease-in-out;
                        }
                    }
                }

                &.half {
                    float:left;
                    width: 49%;
                    padding-right: 2%;

                    &.no-margin {
                        padding-right: 0;
                    }
                }
                &.reference {
                    .clearfix();
                    clear:both;

                    input {
                        width: 80%;
                        float: left;
                    }
                    select {
                        width: 18%;
                        margin-right: 2%;
                        float: left;
                    }
                }

            }
        }
    }
}

.error {
    background: @gwr-pidd-major;
    color: @background-secondary;
    //border-bottom: 3px solid @gwr-pidd-major;
    border-radius:.3em;
    padding: 1em !important;
    margin-top: .5em;

    a {
        color: @background-secondary;
    }
}
.confirmation {
    background: @gwr-pidd-good;
    color: @background-secondary;
    //border-bottom: 3px solid @gwr-pidd-major;
    border-radius:.3em;
    padding: 1em !important;
    margin-top: .5em;

    a {
        color: @background-secondary;
    }
}

table.details {
    width: 100%;
    margin-bottom: 0;

    tr {
        display:block;
        width: 50%;
        float: left;
        margin-bottom: 1em;

        th, td {
            text-align:left;
            display:block;
            width: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;

            padding: 1em 0 0;
        }

        th {
            font-weight: bold;
            font-size: .9em;
            font-family: @font-family-heavy;
            border-bottom: 1px solid @gwr-grey-b;
            padding-bottom: .5em;
        }

        td {
            margin-bottom: 1em;
        }

        &.reference {
            //width: 100%;
        }
        &.issued, &.amount {
            width: 25%;
        }

        &.title {
            width: 20%;
        }

        &.first-name {
            width: 30%;
        }

        &.surname {
            width: 50%;
        }

    }
}


footer {
    font-size: .9em;
    .content {
        border-top: 1px solid @gwr-grey-b;
        padding: 1em;
    }
    .copyright {
        float: left;
        margin: 0 0 1em;
    }

    nav {
        float:right;

        ul {
            padding: 0;
            margin: 0;

            li {
                list-style-type:none;
                float:left;
                margin-left: 1em;
                margin-bottom: 1em;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

@media all and (max-width: 780px) {
    .hero {
        background-position: top right;
    }
}
@media all and (max-width: 740px) {
    footer {
        .copyright {
            margin-bottom: .5em;
            text-align:center;
        }
        .copyright, nav {
            float: none;
        }
        nav {
            ul {
                text-align:center;

                li {
                    display:inline-block;
                    float:none;
                }
            }
        }
    }

    .button button {
        width: 100%;
    }
}
@media all and (max-width: 480px) {
    header.global nav {
        display:none;
    }
    
    table.details tr {
        width: 100% !important;
        margin-bottom:1em;
    }

    footer nav ul li {
        display:block;
        width: 100%;
        margin-left: 0;
    }

    main.container form {
        fieldset {
            div.half {
                width: 100%;
                margin-right: 0;
            }

            div.reference {
                select {
                    width: 28%;
                }
                input {
                    width: 70%;
                }

            }

            div .hint .tooltip {
                left: 0;
            }
        }
    }
}

body.has-cookie-warning {
    padding-bottom: 5em;
}
.cookie-warning {
    background: #F3F2EF;// @background-body;
    border-top: 1px solid darken(@background-body, 10%);
    font-size: .9em;
    position:fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1em 0;

    a {
        color: @gwr-teal;

        &.close {
            float:right;

            img {
                height: 15px;
            }
        }
    }

    p {
        margin: 0;
    }
}

@media print {
    .container {
        width: 100%;
        padding: 0;
    }
    .print-hide,nav {display:none}
}
